<template>
  <div
    class="w-full h-full min-h-full max-w-full bg-zinc-950 flex flex-col gap-4"
  >
    <div class="flex justify-between px-4 md:px-7 pt-4 pb-4">
      <div class="flex items-center gap-1">
        <h2 class="text-white text-3xl xl:text-4xl fugaz uppercase pr-2 pt-0.5">
          {{ $t("sessions.title") }}
        </h2>
      </div>
      <div
        v-if="ready"
        class="flex items-center gap-4 text-white text-lg lg:text-xl"
      >
        <!-- <router-link 
          :to="{ name: 'Charts' }" 
          class="px-2.5 py-1.5 bg-zinc-600 xl:hover:bg-opacity-70 fugaz uppercase flex items-center group"
        >
          <font-awesome-icon class="text-zinc-200 group-hover:text-orange-600 h-5 py-0.5 transition-all duration-200" :icon="['fas', 'chart-pie']" />
        </router-link> -->
        <button
          @click="searching = true"
          class="py-1.5 text-white xl:hover:text-orange-600 fugaz uppercase flex items-center transition-all duration-150"
        >
          <font-awesome-icon
            :icon="['fas', 'magnifying-glass']"
            class="text-orange-600 pr-1 pb-0.5"
          />
          <span class="hidden md:block pl-1.5">{{
            $t("sessions.search")
          }}</span>
        </button>
        <router-link
          :to="{ name: 'Sessions List' }"
          @click="goToSessionsList()"
          class="py-1.5 text-white xl:hover:text-orange-600 fugaz uppercase flex items-center transition-all duration-150"
        >
          <font-awesome-icon
            class="text-orange-600 pr-2 pb-0.5"
            :icon="['fas', 'bars']"
          />
          <span class="hidden md:block">{{ $t("sessions.sessionsList") }}</span>
        </router-link>
      </div>
    </div>
    <div v-if="ready" class="flex flex-col pb-[50px] xl:pb-0 bg-zinc-950">
      <div class="w-full bg-zinc-950 flex flex-col gap-1.5 md:gap-3 z-10">
        <div
          class="w-full flex items-end justify-between border-zinc-700 rounded-sm px-3 md:px-[30px] pt-1 pb-2.5 md:pb-0"
        >
          <div
            class="flex flex-wrap justify-center items-end gap-x-1 gap-y-2 xl:pb-1"
          >
            <div class="flex flex-col z-[30]">
              <div class="flex justify-between">
                <p class="text-zinc-300 whitespace-nowrap text-sm">
                  {{ $t("sessions.dateRange") }}
                </p>
              </div>
              <!-- <v-date-picker
                v-model.range="dateRange"
                is-dark
                mode="dateTime"
                color="pink"
                is24hr
                :max-date="new Date()"
                :locale="appLanguage.long"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center gap-2">
                    <input
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      class="px-2 py-1 w-full md:w-[146px] rounded focus:outline-none bg-zinc-800 text-white"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'arrow-right']"
                      class="h-4 text-white"
                    />
                    <input
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      class="px-2 py-1 w-full md:w-[146px] rounded focus:outline-none bg-zinc-800 text-white"
                    />
                  </div>
                </template>
              </v-date-picker> -->

              <div class="w-[22rem]">
                <DatePicker type="custom" />
              </div>
            </div>
            <div class="pl-3 flex items-cener gap-1 pr-5">
              <button
                @click="changeSessionsType('fun')"
                class="font-bold xl:hover:text-white group flex items-center"
                :class="[
                  sessionsType == 'fun' || sessionsType == 'all'
                    ? 'text-white'
                    : 'text-zinc-400',
                ]"
              >
                <span class="text-lg">{{ $t("sessions.fun") }}</span>
                <font-awesome-icon :icon="['fas', 'gift']" class="h-4 pl-1" />
              </button>
              <label
                class="gap-2 items-center cursor-pointer relative px-2 rounded-md h-max"
              >
                <input
                  type="checkbox"
                  class="sr-only outline-none"
                  @input="changeSessionsType()"
                />
                <div
                  :class="[
                    'block w-14 h-8 rounded-full relative transition-all duration-300 bg-zinc-800',
                    {
                      // 'bg-green-500': sessionsType === 'real',
                      // 'bg-red-500': sessionsType === 'fun',
                      // 'bg-zinc-600': sessionsType === 'all',
                    },
                  ]"
                >
                  <div
                    class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
                    :class="{
                      'translate-x-6': sessionsType === 'real',
                      'translate-x-3': sessionsType === 'all',
                    }"
                  ></div>
                </div>
              </label>
              <button
                @click="changeSessionsType('real')"
                class="font-bold xl:hover:text-white group flex items-center"
                :class="[
                  sessionsType == 'real' || sessionsType == 'all'
                    ? 'text-white'
                    : 'text-zinc-400',
                ]"
              >
                <span class="text-lg">{{ $t("sessions.real") }}</span>
                <font-awesome-icon
                  :icon="['fas', 'euro-sign']"
                  class="h-4 pl-1"
                />
              </button>
            </div>
            <button
              @click="getAllSessions"
              :disabled="loading"
              class="hidden md:block px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
            >
              {{ $t("sessions.submit") }}
            </button>
            <div
              v-if="loading"
              class="hidden md:flex items-center justify-center pb-1.5 md:pb-0"
            >
              <img
                src="../../assets/loading2.png"
                alt="loading icon"
                class="h-6 md:h-7 text-zinc-200 animate-spin"
              />
            </div>
            <!-- <div v-else class="">{{ actualSessions.length }} sessions found</div> -->
          </div>
          <div class="hidden xl:flex items-center gap-2">
            <!-- <button
              v-if="!fetchingSessions"
              @click="openFetch=false"
              class="hidden md:block px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
              :disabled="fetchingSessions"
            >
              Cancel
            </button> -->
            <div
              v-if="ready && userRoles.isLudusAdmin && !fetchingSessions"
              class="w-max"
              :class="
                appLanguage.long == 'en-GB' ? 'min-w-[330px]' : 'min-w-[440px]'
              "
            >
              <Multiselect
                mode="multiple"
                v-model="platformsToFetch"
                :options="platformsSortedByName"
                label="name"
                value-prop="id"
                :placeholder="$t('sessions.fetchPlaceholder')"
                :hide-selected="false"
                :caret="false"
                :close-on-select="false"
                :searchable="true"
                :multiple-label="
                  (values) => `${values.length} Platforms selected`
                "
              />
            </div>
            <button
              v-if="ready && userRoles.isLudusAdmin && !fetchingSessions"
              @click="toggleFetch"
              class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
              :disabled="fetchingSessions"
            >
              {{ $t("sessions.fetch") }}
            </button>
            <div
              v-if="fetchingSessions"
              class="flex items-center gap-2 fugaz uppercase font-thin text-zinc-200 pl-3"
            >
              {{ $t("sessions.fetching") }}
              <img
                src="../../assets/loading2.png"
                alt="loading icon"
                class="text-zinc-600 dark:text-zinc-400 h-6 animate-spin"
              />
            </div>
          </div>
        </div>
        <!-- mobile submit -->
        <div class="flex md:hidden items-center justify-center gap-1 w-full">
          <button
            @click="getAllSessions"
            :disabled="loading"
            class="px-3 pt-1 pb-[3px] text-orange-600 bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
          >
            {{ $t("sessions.submit") }}
          </button>
          <div v-if="loading" class="flex items-center justify-center">
            <img
              src="../../assets/loading2.png"
              alt="loading icon"
              class="h-6 md:h-7 text-zinc-200 animate-spin"
            />
          </div>
        </div>
        <div class="flex flex-col px-3 md:px-7 gap-1 pb-4 mt-2 md:mt-0">
          <div
            class="w-full flex flex-col xl:flex-row items-start xl:items-end justify-between text-white pb-0 xl:pb-2"
          >
            <div class="flex items-start justify-start flex-wrap gap-2.5">
              <div
                class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
              >
                <span class="text-sm">{{ $t("sessions.all") }}</span>
                <span class="text-white text-xl">{{ sessionsCount }}</span>
              </div>
              <div
                class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
              >
                <span class="text-sm">{{ $t("sessions.open") }}</span>
                <span class="text-cyan-500 text-xl">{{ openSessions }}</span>
              </div>
              <div
                class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
              >
                <span class="text-sm">{{ $t("sessions.closed") }}</span>
                <span class="text-green-500 text-xl">{{ closedSessions }}</span>
              </div>
              <!-- bet single -->
              <div
                v-if="
                  (allBetFun === null && allBetReal === null) ||
                  (allBetFun !== null && allBetReal === null) ||
                  (allBetFun === null && allBetReal !== null)
                "
                class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
              >
                <span class="text-sm">Bet</span>
                <span
                  v-if="allBetFun === null && allBetReal === null"
                  class="text-xl text-white"
                >
                  {{ getCurrencyValue(0) }}
                </span>
                <span
                  v-if="allBetFun !== null && allBetReal === null"
                  class="text-xl text-white"
                >
                  {{ getCurrencyValue(allBetFun) }}
                </span>
                <span
                  v-if="allBetFun === null && allBetReal !== null"
                  class="text-xl text-white"
                >
                  {{ getCurrencyValue(allBetReal) }}
                </span>
              </div>
              <!-- win single -->
              <div
                v-if="
                  (allWinFun === null && allWinReal === null) ||
                  (allWinFun !== null && allWinReal === null) ||
                  (allWinFun === null && allWinReal !== null)
                "
                class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
              >
                <span class="text-sm">Win</span>
                <span
                  v-if="allWinFun === null && allWinReal === null"
                  class="text-xl text-white"
                >
                  {{ getCurrencyValue(0) }}
                </span>
                <span
                  v-if="allWinFun !== null && allWinReal === null"
                  class="text-xl text-white"
                >
                  {{ getCurrencyValue(allWinFun) }}
                </span>
                <span
                  v-if="allWinFun === null && allWinReal !== null"
                  class="text-xl text-white"
                >
                  {{ getCurrencyValue(allWinReal) }}
                </span>
              </div>
              <!-- gross win single -->
              <div
                v-if="
                  (grossWinFun === null && grossWinReal === null) ||
                  (grossWinFun !== null && grossWinReal === null) ||
                  (grossWinFun === null && grossWinReal !== null)
                "
                class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
              >
                <span class="text-sm">Gross win</span>
                <div class="text-xl">
                  <span v-if="grossWinFun === null && grossWinReal === null">
                    {{ getCurrencyValue(0) }}
                  </span>
                  <span
                    v-if="grossWinFun !== null && grossWinReal === null"
                    :class="grossWinFun > 0 ? 'text-green-500' : 'text-red-500'"
                  >
                    {{ getCurrencyValue(grossWinFun) }}
                  </span>
                  <span
                    v-if="grossWinFun === null && grossWinReal !== null"
                    :class="
                      grossWinReal > 0 ? 'text-green-500' : 'text-red-500'
                    "
                  >
                    {{ getCurrencyValue(grossWinReal) }}
                  </span>
                </div>
              </div>
              <div
                v-if="allBetFun !== null && allBetReal !== null"
                class="flex flex-col px-1.5 pt-1 pb-1.5 bg-zinc-800 rounded-md"
              >
                <div class="flex items-center gap-1.5">
                  <span class="fugaz uppercase pl-1">{{
                    $t("sessions.fun")
                  }}</span>
                  <font-awesome-icon
                    :icon="['fas', 'gift']"
                    class="h-3.5 pb-0.5"
                  />
                </div>
                <div class="flex items-center gap-1.5 rounded-md">
                  <div
                    class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                  >
                    <span class="text-sm">Bet</span>
                    <span class="text-xl text-white">
                      {{ getCurrencyValue(allBetFun) }}
                    </span>
                  </div>
                  <div
                    class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                  >
                    <span class="text-sm">Win</span>
                    <span class="text-xl text-white">
                      {{ getCurrencyValue(allBetFun) }}
                    </span>
                  </div>
                  <div
                    class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                  >
                    <span class="text-sm">Gross Win</span>
                    <span
                      class="text-xl"
                      :class="
                        grossWinFun > 0 ? 'text-green-500' : 'text-red-500'
                      "
                    >
                      {{ getCurrencyValue(grossWinFun) }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="allBetFun !== null && allBetReal !== null"
                class="flex flex-col px-1.5 pt-1 pb-1.5 bg-zinc-800 rounded-md"
              >
                <div class="flex items-center gap-1.5">
                  <span class="fugaz uppercase pl-1">{{
                    $t("sessions.real")
                  }}</span>
                  <font-awesome-icon
                    :icon="['fas', 'euro-sign']"
                    class="h-4 pb-0.5"
                  />
                </div>
                <div class="flex items-center gap-1.5 rounded-md">
                  <div
                    class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                  >
                    <span class="text-sm">Bet</span>
                    <span class="text-xl text-white">
                      {{ getCurrencyValue(allBetReal) }}
                    </span>
                  </div>
                  <div
                    class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                  >
                    <span class="text-sm">Win</span>
                    <span class="text-xl text-white">
                      {{ getCurrencyValue(allBetReal) }}
                    </span>
                  </div>
                  <div
                    class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                  >
                    <span class="text-sm">Gross Win</span>
                    <span
                      class="text-xl"
                      :class="
                        grossWinReal > 0 ? 'text-green-500' : 'text-red-500'
                      "
                    >
                      {{ getCurrencyValue(grossWinReal) }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
              >
                <span class="text-sm">{{ $t("sessions.players") }}</span>
                <span class="text-white text-xl">{{ playersCount }}</span>
              </div>
            </div>
            <div
              class="flex items-center justify-start xl:justify-center gap-2 fugaz w-full xl:w-max pt-3 xl:pt-0"
            >
              <button
                @click="displayType = 'platforms'"
                class="px-1 uppercase xl:hover:text-orange-600 transition-all duration-100"
                :class="
                  displayType === 'platforms' ? 'text-orange-600' : 'text-white'
                "
              >
                {{ $t("sessions.platforms") }}
              </button>
              <button
                @click="displayType = 'games'"
                class="px-1 uppercase xl:hover:text-orange-600 transition-all duration-100"
                :class="
                  displayType === 'games' ? 'text-orange-600' : 'text-white'
                "
              >
                {{ $t("sessions.games") }}
              </button>
            </div>
          </div>
          <div
            class="w-full flex items-start justify-between gap-2 rounded-sm text-white"
          >
            <div v-show="displayType === 'platforms'" class="w-full">
              <table
                class="table table-fixed w-full border-separate border-spacing-y-1"
              >
                <thead
                  class="bg-zinc-800 py-3 pl-4 rounded-sm text-lg fugaz uppercase sticky top-0 z-20 shadow-sm"
                >
                  <tr>
                    <th
                      class="table-cell py-2 xl:py-3 pl-3 fugaz uppercase text-left"
                    >
                      {{
                        platformOperators.length > 0
                          ? $t("sessions.operators")
                          : $t("sessions.platforms")
                      }}
                    </th>
                    <th
                      @click="setPlatformsSorting('bet')"
                      class="hidden xl:table-cell text-center truncate fugaz uppercase cursor-pointer w-[130px]"
                    >
                      <!-- ghost icon -->
                      <font-awesome-icon
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                      Bet
                      <font-awesome-icon
                        v-if="sortPlatformsBy == 'bet'"
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="sortPlatformsBy == 'betInverse'"
                        :icon="['fas', 'sort-up']"
                        class="pt-0.5 px-1.5"
                      />
                      <!-- ghost icon -->
                      <font-awesome-icon
                        v-if="
                          sortPlatformsBy !== 'bet' &&
                          sortPlatformsBy !== 'betInverse'
                        "
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                    </th>
                    <th
                      @click="setPlatformsSorting('win')"
                      class="hidden xl:table-cell text-center truncate fugaz uppercase cursor-pointer w-[130px]"
                    >
                      <!-- ghost icon -->
                      <font-awesome-icon
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                      Win
                      <font-awesome-icon
                        v-if="sortPlatformsBy == 'win'"
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="sortPlatformsBy == 'winInverse'"
                        :icon="['fas', 'sort-up']"
                        class="pt-0.5 px-1.5"
                      />
                      <!-- ghost icon -->
                      <font-awesome-icon
                        v-if="
                          sortPlatformsBy !== 'win' &&
                          sortPlatformsBy !== 'winInverse'
                        "
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                    </th>
                    <th
                      @click="setPlatformsSorting('grossWin')"
                      class="hidden xl:table-cell text-center truncate fugaz uppercase cursor-pointer w-[160px]"
                    >
                      <!-- ghost icon -->
                      <font-awesome-icon
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                      Gross Win
                      <font-awesome-icon
                        v-if="sortPlatformsBy == 'grossWin'"
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="sortPlatformsBy == 'grossWinInverse'"
                        :icon="['fas', 'sort-up']"
                        class="pt-0.5 px-1.5"
                      />
                      <!-- ghost icon -->
                      <font-awesome-icon
                        v-if="
                          sortPlatformsBy !== 'grossWin' &&
                          sortPlatformsBy !== 'grossWinInverse'
                        "
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                    </th>
                    <th
                      @click="setPlatformsSorting('sessionsCount')"
                      class="hidden md:table-cell text-center truncate fugaz uppercase cursor-pointer w-[150px]"
                    >
                      <!-- ghost icon -->
                      <font-awesome-icon
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                      {{ $t("sessions.title") }}
                      <font-awesome-icon
                        v-if="sortPlatformsBy == 'sessionsCount'"
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="sortPlatformsBy == 'sessionsCountInverse'"
                        :icon="['fas', 'sort-up']"
                        class="pt-0.5 px-1.5"
                      />
                      <!-- ghost icon -->
                      <font-awesome-icon
                        v-if="
                          sortPlatformsBy !== 'sessionsCount' &&
                          sortPlatformsBy !== 'sessionsCountInverse'
                        "
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                    </th>
                  </tr>
                  <!-- <div class="flex items-center gap-2" :class="hideRealWins ? 'justify-end' : 'justify-between '"> -->
                  <!-- </div> -->
                </thead>
                <tbody
                  v-if="Object.keys(user.attributes).length > 0"
                  class="z-10"
                >
                  <tr
                    v-for="(operator, index) in platformOperators"
                    :key="operator.id"
                    @click="goToSessionsList(operator, 'operator')"
                    class="py-3 pl-4 fugaz uppercase cursor-pointer xl:hover:brightness-105 text-zinc-200 xl:hover:text-white"
                    :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
                  >
                    <td class="table-cell py-2.5 pl-3 fugaz uppercase">
                      {{ operator.name }}
                    </td>
                    <td class="hidden xl:table-cell text-center">
                      {{
                        sessionsCount > 0 ? getCurrencyValue(operator.bet) : ""
                      }}
                    </td>
                    <td class="hidden xl:table-cell text-center">
                      {{
                        sessionsCount > 0 ? getCurrencyValue(operator.win) : ""
                      }}
                    </td>
                    <td
                      class="hidden xl:table-cell text-center"
                      :class="
                        operator.grossWin >= 0
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                    >
                      {{
                        sessionsCount > 0
                          ? getCurrencyValue(operator.grossWin)
                          : ""
                      }}
                    </td>
                    <td class="hidden md:table-cell text-center">
                      {{ sessionsCount > 0 ? operator.sessionsCount : "" }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else class="<-10">
                  <tr
                    v-for="(platform, index) in allPlatforms"
                    @click="goToSessionsList(platform, 'platform')"
                    :key="platform.id"
                    class="py-2 pl-4 fugaz uppercase cursor-pointer xl:hover:brightness-105 text-zinc-200 xl:hover:text-white"
                    :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
                  >
                    <td class="table-cell py-2.5 pl-3 fugaz uppercase">
                      {{ platform.name }}
                    </td>
                    <td class="hidden xl:table-cell text-center">
                      {{
                        sessionsCount > 0 ? getCurrencyValue(platform.bet) : ""
                      }}
                    </td>
                    <td class="hidden xl:table-cell text-center">
                      {{
                        sessionsCount > 0 ? getCurrencyValue(platform.win) : ""
                      }}
                    </td>
                    <td
                      class="hidden xl:table-cell text-center"
                      :class="
                        platform.grossWin >= 0
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                    >
                      {{
                        sessionsCount > 0
                          ? getCurrencyValue(platform.grossWin)
                          : ""
                      }}
                    </td>
                    <td class="hidden md:table-cell text-center">
                      {{ sessionsCount > 0 ? platform.sessionsCount : "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-show="displayType === 'games'"
              class="flex flex-col gap-1 w-full h-full"
            >
              <table
                class="table table-auto w-full border-separate border-spacing-y-1"
              >
                <thead
                  class="bg-zinc-800 py-1.5 rounded-sm text-lg fugaz uppercase sticky top-0 z-20 shadow-sm"
                >
                  <tr>
                    <th class="table-cell pl-4 py-2 xl:py-3 fugaz uppercase">
                      {{ $t("sessions.game") }}
                    </th>
                    <!-- <th
                      @click="openOrCloseCharts('games', true)"
                      class="px-3 py-2.5 mr-2 rounded-sm bg-zinc-600 xl:hover:bg-zinc-700 table-cell"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'chart-column']"
                        class="text-white h-5"
                      />
                    </th> -->
                    <!-- <div class="flex w-[60%]" :class="hideRealWins ? 'justify-end' : 'justify-between '"> -->
                    <th
                      @click="setGamesSorting('bet')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden xl:table-cell w-[130px]"
                    >
                      <div class="flex items-center justify-center">
                        <!-- ghost icon -->
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                        Bet
                        <font-awesome-icon
                          v-if="sortGamesBy == 'bet'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'betInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 pl-2"
                        />
                        <!-- ghost icon -->
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'bet' &&
                            sortGamesBy !== 'betInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                      </div>
                    </th>
                    <th
                      @click="setGamesSorting('win')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden xl:table-cell w-[130px]"
                    >
                      <div class="flex items-center justify-center">
                        <!-- ghost icon -->
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                        Win
                        <font-awesome-icon
                          v-if="sortGamesBy == 'win'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'winInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 pl-2"
                        />
                        <!-- ghost icon -->
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'win' &&
                            sortGamesBy !== 'winInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                      </div>
                    </th>
                    <th
                      @click="setGamesSorting('grossWin')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden xl:table-cell w-[130px]"
                    >
                      <div class="flex items-center justify-center">
                        <!-- ghost icon -->
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                        Gross win
                        <font-awesome-icon
                          v-if="sortGamesBy == 'grossWin'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'grossWinInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 pl-2"
                        />
                        <!-- ghost icon -->
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'grossWin' &&
                            sortGamesBy !== 'grossWinInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                      </div>
                    </th>
                    <th
                      @click="setGamesSorting('roundsCount')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden xl:table-cell w-[130px]"
                    >
                      <div class="flex items-center justify-center">
                        <!-- ghost icon -->
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                        {{ $t("sessions.rounds") }}
                        <font-awesome-icon
                          v-if="sortGamesBy == 'roundsCount'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'roundsCountInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 pl-2"
                        />
                        <!-- ghost icon -->
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'roundsCount' &&
                            sortGamesBy !== 'roundsCountInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                      </div>
                    </th>
                    <th
                      @click="setGamesSorting('sessionsCount')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden md:table-cell w-[155px]"
                    >
                      <div class="flex items-center justify-center">
                        <!-- ghost icon -->
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 px-2 invisible"
                        />
                        {{ $t("sessions.title") }}
                        <font-awesome-icon
                          v-if="sortGamesBy == 'sessionsCount'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 px-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'sessionsCountInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 px-2"
                        />
                        <!-- ghost icon -->
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'sessionsCount' &&
                            sortGamesBy !== 'sessionsCountInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 px-2 invisible"
                        />
                      </div>
                    </th>
                    <!-- </div> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(game, index) in allGames.filter(
                      (g) => g.sessionsCount > 0
                    )"
                    :key="game.id"
                    @click="goToSessionsList(game, 'game')"
                    class="py-6 fugaz uppercase z-10 cursor-pointer xl:hover:brightness-105"
                    :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
                    :style="
                      index % 2 === 0
                        ? getMiniBannerBackground(game, 'gray')
                        : getMiniBannerBackground(game, 'black')
                    "
                  >
                    <td
                      class="pl-3 py-4 xl:py-6 text-lg table-cell h-full max-w-[150px] truncate text-center"
                    >
                      <span class="bg-zinc-800 bg-opacity-50 px-1.5 rounded-sm">
                        {{
                          game.sub_name
                            ? `${game.name} - ${game.sub_name}`
                            : game.name
                        }}
                      </span>
                    </td>
                    <td class="w-max hidden xl:table-cell text-center">
                      <span
                        class="bg-zinc-800 bg-opacity-25 px-1.5 rounded-sm"
                        >{{ getCurrencyValue(game.bet) }}</span
                      >
                    </td>
                    <td class="w-max hidden xl:table-cell text-center">
                      <span
                        class="bg-zinc-800 bg-opacity-25 px-1.5 rounded-sm"
                        >{{ getCurrencyValue(game.win) }}</span
                      >
                    </td>
                    <td
                      class="w-max hidden xl:table-cell text-center"
                      :class="
                        game.grossWin >= 0 ? 'text-green-500' : 'text-red-500'
                      "
                    >
                      <span
                        class="bg-zinc-800 bg-opacity-25 px-1.5 rounded-sm"
                        >{{ getCurrencyValue(game.grossWin) }}</span
                      >
                    </td>
                    <td class="w-max hidden xl:table-cell text-center">
                      <span class="px-1 rounded-sm">{{
                        game.roundsCount
                      }}</span>
                    </td>
                    <td class="w-max hidden md:table-cell text-center">
                      <span class="px-1 rounded-sm">{{
                        game.sessionsCount
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Transition>
        <SearchSession
          v-if="searching"
          :userPlatform="userPlatform?.id"
          :closeSearch="closeSearch"
          :allPlatforms="allPlatforms"
        />
      </Transition>
      <Transition>
        <GamesCharts
          v-if="charts.games"
          :openOrCloseCharts="openOrCloseCharts"
          :games="allGames"
          class="z-20"
        />
      </Transition>
    </div>
    <div
      v-else
      class="w-full h-[90vh] flex justify-center items-center gap-3 text-white bg-zinc-950"
    >
      <img
        v-if="!serverError"
        src="../../assets/hub-full-logo.png"
        alt="gai hub logo"
        class="h-24 w-auto animate-pulse"
      />
      <div v-else>
        {{ $t("serverError") }}
        <button @click="$router.go()" class="text-blue-600">
          {{ $t("reload") }}
        </button>
        {{ $t("tryAgain") }}
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/services/axios";
import Session from "@/components/sessions/Session.vue";
import GamesCharts from "@/components/sessions/GamesCharts.vue";
import SearchSession from "@/components/sessions/SearchSession.vue";
import PlatformCharts from "@/components/sessions/PlatformCharts.vue";
import Multiselect from "@vueform/multiselect";
import { useDateFormatter } from "@/helpers/dateFormatter.js";
import DatePicker from "@/components/DatePicker";

export default {
  data() {
    return {
      ready: false,
      loading: false,
      serverError: false,
      allPlatforms: [],
      platformOperators: [],
      allGames: [],
      searching: false,
      loadingSearch: false,
      search: "",
      userPlatform: null,
      sessionFound: false,
      sessionNotFound: false,
      sessionsCount: 0,
      openSessions: 0,
      closedSessions: 0,
      allBetFun: null,
      allBetReal: null,
      allWinFun: null,
      allWinReal: null,
      grossWinReal: null,
      grossWinFun: null,
      netWin: 0,
      sortGamesBy: "sessionsCount",
      sortPlatformsBy: "sessionsCount",
      selectedPlatform: {},
      charts: {},
      sessionsType: "real",
      hideRealWins: false,
      displayType: "platforms",
      openFetch: false,
      platformsToFetch: [],
      playersCount: 0,
      mountTimer: null,
    };
  },
  components: {
    Session,
    GamesCharts,
    PlatformCharts,
    Multiselect,
    SearchSession,
    DatePicker,
  },
  computed: {
    dateRange() {
      return this.$store.state.filters.date;
    },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    platformsSortedByName() {
      const platforms = [...this.allPlatforms];
      platforms.sort((a, b) => a.name.localeCompare(b.name));
      return platforms;
    },
    fetchingSessions() {
      return this.$store.getters["jobs/fetching"];
    },
    lastJob() {
      return this.$store.getters["jobs/lastJob"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      if (this.$store.getters["sessions/getPlatforms"].length === 0) {
        await this.getAllPlatforms();
      } else {
        this.allPlatforms = this.$store.getters["sessions/getPlatforms"];
      }
      this.ready = true;
    }, 500);
  },
  beforeUnmount() {
    clearTimeout(this.mountTimer);
  },
  methods: {
    toggleFetch() {
      this.fetchAllSessions();
    },
    goToSessionsList(relatedObject = null, type = null) {
      if (this.allPlatforms.length > 0) {
        this.$store.dispatch("sessions/setPlatformsAction", this.allPlatforms);
      }
      if (type !== null) this.$router.push({ name: "Sessions List" });
    },
    changeSessionsType(type = null) {
      if (type !== null) {
        this.sessionsType = type;
        return;
      }
      if (this.sessionsType === "fun") {
        this.sessionsType = "real";
        return;
      }
      if (this.sessionsType === "real") {
        this.sessionsType = "all";
        return;
      }
      if (this.sessionsType === "all") {
        this.sessionsType = "fun";
        return;
      }
    },
    async getPlatformOperators() {
      let plat = null;
      if (Object.keys(this.user.attributes).length > 0) {
        plat = this.allPlatforms.find(
          (plat) => plat.name == this.user.attributes.platform[0]
        );
      }
      if (plat) {
        const data = (await request.get(`/platforms/${plat.id}/operators`))
          .data;
        this.platformOperators = data;
      }
    },
    // getMiniBannerBackground(game) {
    //   const image = game.game_media.find(
    //     (image) => image.isMiniBanner === true
    //   );
    //   if(image) return `background-image: url("${image?.url}");`;
    // },
    getMiniBannerBackground(game, color) {
      const image = game.game_media.find(
        (image) => image.isMiniBanner === true
      );
      if (image) {
        if (color === "gray") {
          // rgb(29 29 31
          return `background-image: linear-gradient(to left, rgba(29, 29, 31, 1) 28%, rgba(29, 29, 31, 1) 0%, rgba(29, 29, 31, 0)), url("${image.url}");`;
        }
        if (color === "black") {
          // 24 24 27
          return `background-image: linear-gradient(to left, rgba(24, 24, 27, 1) 28%, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 27, 0)), url("${image.url}");`;
        }
      } else
        return `background-image: linear-gradient(to left, rgba(24, 24, 27, 1) 28%, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 27, 0)), rgba(24, 24, 27, 0));`;
    },
    openPlatformCharts(platform) {
      this.charts.platform = true;
      this.selectedPlatform = platform;
    },
    openOrCloseCharts(type, value) {
      this.charts[type] = value;
    },
    setGamesSorting(sort, force = false) {
      if (this.sortGamesBy == sort && !force) {
        const sortInverse = `${sort}Inverse`;
        this.sortGamesBy = sortInverse;
        this.allGames.sort((a, b) => a[sort] - b[sort]);
      } else {
        this.sortGamesBy = sort;
        this.allGames.sort((a, b) => b[sort] - a[sort]);
      }
    },
    setPlatformsSorting(sort, force = false) {
      if (this.sortPlatformsBy == sort && !force) {
        const sortInverse = `${sort}Inverse`;
        this.sortPlatformsBy = sortInverse;
        this.allPlatforms.sort((a, b) => a[sort] - b[sort]);
        this.platformOperators.sort((a, b) => a[sort] - b[sort]);
      } else {
        this.sortPlatformsBy = sort;
        this.allPlatforms.sort((a, b) => b[sort] - a[sort]);
        this.platformOperators.sort((a, b) => b[sort] - a[sort]);
      }
    },
    resetJobProgress() {
      if (this.$route.name === "Sessions") {
        this.getAllSessions();
      }
    },
    closeSearch() {
      this.searching = false;
      this.$store.dispatch("sessions/setSessionAction", {});
    },
    getSessionEarnings(session) {
      const bet = session.bet === null ? 0 : parseFloat(session.bet);
      const win = session.win === null ? 0 : parseFloat(session.win);
      const grossWin = bet - win;
      // const italianTax = (process.env.VUE_APP_ITALIAN_TAX / 100) * grossWin
      // const final = grossWin - italianTax
      return { bet, win, grossWin };
    },
    getGameValues(game, allSessions) {
      let bet, win, grossWin;
      bet = win = grossWin = 0;
      const sessions = allSessions.filter((s) => s.game.slug == game.slug);
      for (const session of sessions) {
        bet += this.getSessionEarnings(session).bet;
        win += this.getSessionEarnings(session).win;
        grossWin += this.getSessionEarnings(session).grossWin;
      }
      // console.log(bet, win, grossWin)
      return { bet, win, grossWin };
    },
    getPlatformValues(platform, allSessions) {
      let bet, win, grossWin;
      bet = win = grossWin = 0;
      const platSessions = allSessions.filter(
        (s) => s.platform.id == platform.id
      );
      for (const session of platSessions) {
        bet += this.getSessionEarnings(session).bet;
        win += this.getSessionEarnings(session).win;
        grossWin += this.getSessionEarnings(session).grossWin;
      }
      return { bet, win, grossWin };
    },
    getOperatorValues(operator, allSessions) {
      let bet, win, grossWin;
      bet = win = grossWin = 0;
      const operatorSessions = allSessions.filter(
        (s) => s.operatorId == operator.id
      );
      for (const session of operatorSessions) {
        bet += this.getSessionEarnings(session).bet;
        win += this.getSessionEarnings(session).win;
        grossWin += this.getSessionEarnings(session).grossWin;
      }
      return { bet, win, grossWin };
    },
    getGameSessionsCount(game, sessions) {
      return sessions.filter((s) => s.game.slug === game.slug).length;
    },
    getGameRoundsCount(game, sessions) {
      const gameSessions = sessions.filter((s) => s.game.slug === game.slug);
      let rounds = 0;
      for (const gameSession of gameSessions) {
        rounds += gameSession.currentRound;
      }
      return rounds;
    },
    getPlatformSessionsCount(platform, sessions) {
      return sessions.filter((s) => s.platform.id === platform.id).length;
    },
    getOperatorSessionsCount(operator, sessions) {
      const count = sessions.filter((s) => s.operatorId === operator.id).length;
      return count ? count : 0;
    },
    getTodayStart() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return this.lastJob?.from || today;
    },
    getTodayEnd() {
      const today = new Date();
      today.setHours(23, 59, 59, 59);
      return this.lastJob?.to || today;
    },
    async getAllPlatforms(sessions = []) {
      try {
        if (Object.keys(this.user.attributes).length > 0) {
          const platform = (
            await request.get(
              `/platforms/byName/${this.user.attributes.platform[0]}`
            )
          ).data;
          this.userPlatform = platform;
          const data = (
            await request.get(`/platforms/${platform.id}/operators`)
          ).data;
          this.platformOperators = data.map((operator) => ({
            ...operator,
            bet: this.getOperatorValues(operator, sessions).bet,
            win: this.getOperatorValues(operator, sessions).win,
            grossWin: this.getOperatorValues(operator, sessions).grossWin,
            sessionsCount: this.getOperatorSessionsCount(operator, sessions),
          }));
        } else {
          const platforms = (await request.get("/platforms?active=1")).data.rows;
          platforms.sort((a, b) =>
            // this.getPlatformSessionsCount(b, sessions) - this.getPlatformSessionsCount(a, sessions)
            a.name.localeCompare(b.name)
          );
          this.allPlatforms = platforms.map((platform) => ({
            ...platform,
            bet: this.getPlatformValues(platform, sessions).bet,
            win: this.getPlatformValues(platform, sessions).win,
            grossWin: this.getPlatformValues(platform, sessions).grossWin,
            sessionsCount: this.getPlatformSessionsCount(platform, sessions),
          }));
        }
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
    async getAllGames(sessions) {
      try {
        const games = (
          await request.get("/catalog?with=game_media&mediaTypes=mini")
        ).data.rows.map((game) => ({
          ...game,
          sessionsCount: this.getGameSessionsCount(game, sessions),
          roundsCount: this.getGameRoundsCount(game, sessions),
          bet: this.getGameValues(game, sessions).bet,
          win: this.getGameValues(game, sessions).win,
          grossWin: this.getGameValues(game, sessions).grossWin,
        }));

        // console.log(games);

        this.allGames = games.sort(
          (a, b) =>
            this.getGameSessionsCount(b, sessions) -
            this.getGameSessionsCount(a, sessions)
        );
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
    async getAllSessions() {
      this.loading = true;
      //get totals
      try {
        let sessionTypeId = null;

        const sqlFrom = useDateFormatter(this.dateRange.start);
        const sqlTo = useDateFormatter(this.dateRange.end);

        // if (this.sessionsType === "real" || this.sessionsType === "fun") {
        if (this.sessionsType == "real") {
          sessionTypeId = 1;
          this.allBetFun = null;
          this.allWinFun = null;
          this.grossWinFun = null;
        } else if (this.sessionsType == "fun") {
          this.hideRealWins = true;
          sessionTypeId = 4;
          this.allBetReal = null;
          this.allWinReal = null;
          this.grossWinReal = null;
        }

        const obj = {
          from: sqlFrom,
          to: sqlTo,
          sessionTypeId: sessionTypeId,
        };
        const data = btoa(JSON.stringify(obj));
        const res = await request.get(`/bookkeeping/stats?a=${data}`);

        const totals = res.data.grandTotals;
        this.playersCount = totals.usersCount;
        this.openSessions = totals.sessionsOpen;
        this.closedSessions = totals.sessionsClosed;
        this.sessionsCount = totals.sessionsTotal;

        if (this.sessionsType === "real") {
          this.allBetReal = totals.bet;
          this.allWinReal = totals.win;
          this.grossWinReal = totals.grosswin;
          this.netWinReal = totals.netwin;
        } else if (this.sessionsType === "fun") {
          this.allBetFun = totals.bet;
          this.allWinFun = totals.win;
          this.grossWinFun = totals.grosswin;
          this.netWinFun = totals.net;
        } else if (this.sessionsType === "all") {
          const realObj = {
            from: sqlFrom,
            to: sqlTo,
            sessionTypeId: 1,
          };

          let data = btoa(JSON.stringify(realObj));
          let res = await request.get(`/bookkeeping/stats?a=${data}`);
          let totals = res.data.grandTotals;
          this.allBetReal = totals.bet;
          this.allWinReal = totals.win;
          this.grossWinReal = totals.grosswin;
          this.netWinReal = totals.netwin;

          const funObj = {
            from: sqlFrom,
            to: sqlTo,
            sessionTypeId: 4,
          };

          data = btoa(JSON.stringify(funObj));
          res = await request.get(`/bookkeeping/stats?a=${data}`);
          totals = res.data.grandTotals;
          this.allBetFun = totals.bet;
          this.allWinFun = totals.win;
          this.grossWinFun = totals.grosswin;
          this.netWinFun = totals.net;
        }
        //get sessions
        const sessionsResponse = (
          await request.get(
            `/bookkeeping/sessions/range?from=${sqlFrom}&to=${sqlTo}&sessionTypeId=${sessionTypeId}&isOpen=null`
          )
        ).data;
        const sessions = sessionsResponse.rows;
        await this.getAllPlatforms(sessions);
        await this.getAllGames(sessions);

        this.setGamesSorting(this.sortGamesBy, true);
        this.setPlatformsSorting(this.sortPlatformsBy, true);
        if (this.sessionsType !== "fun") this.hideRealWins = false;
      } catch (e) {
        console.log(e);
        // TODO review this DRAMATIC error handling approach
        this.serverError = true;
      }

      this.loading = false;
    },
    isIntervalMoreThan31Days(dateString1, dateString2) {
      const date1 = new Date(dateString1);
      const date2 = new Date(dateString2);

      // Calculate the difference in milliseconds
      const timeDifference = Math.abs(date2 - date1);

      // Calculate the number of days
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

      // Check if the interval is more than 31 days
      return daysDifference > 31;
    },
    async fetchAllSessions() {
      if (
        this.isIntervalMoreThan31Days(this.dateRange.start, this.dateRange.end)
      ) {
        this.$toast.error(
          "You can't fetch more than 31 days of sessions at the same time",
          { duration: 5000 }
        );
        return;
      }
      this.$toast.open({
        message: "Fetching sessions...",
        type: "info",
        duration: 2000,
        position: "bottom",
      });
      try {
        // this.allSessions = (await request.get('/bookkeeping/sessions')).data.rows
        const sqlFrom = new Date(this.dateRange.start)
          .toISOString()
          .slice(0, 19);
        const sqlTo = new Date(this.dateRange.end).toISOString().slice(0, 19);
        // console.log('sqlFrom: ' + sqlFrom + ' sqlTo: ' + sqlTo);
        const { jobId } = (
          await request.get(
            `/bookkeeping/sessions/fetch?dateStart=${sqlFrom}&dateEnd=${sqlTo}&socketId=${this.socket.id}&platformIds=${this.platformsToFetch}`
          )
        ).data;

        this.$store.dispatch("jobs/setActiveJobAction", {
          jobId,
          userId: this.user.userId,
          state: "called",
        });
        // this.$store.dispatch('jobs/setLastJobAction', {
        //   from: this.dateRange.start,
        //   to: this.dateRange.end,
        // })
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
/* tbody tr:nth-child(even) {
  background-color: rgb(63 63 70);
} */
/* tbody tr:nth-child(even) {
  background-color: rgb(34, 34, 34);
} */
</style>