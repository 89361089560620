<template>
  <div class="w-full min-h-full bg-zinc-950">
    <div class="h-full w-full flex flex-col gap-4 relative pb-[50px] xl:pb-0">
      <!-- header  -->
      <div class="flex justify-between items-center rounded-t-md w-full px-4 md:px-7 pt-4 pb-4">
        <h2 class="fugaz uppercase text-3xl xl:text-4xl pt-0.5 text-white">
          {{ $t('bets.title') }}
        </h2>
      </div>
      <div v-if="ready" class="h-full w-full flex flex-col gap-2 relative ">
        <div class="w-full flex flex-col xl:flex-row justify-between gap-2 px-3 md:px-7">
          <div class="w-full h-max px-4 pb-5 pt-3 bg-zinc-800 rounded-sm">
            <h2 class="fugaz uppercase text-lg dark:text-white mb-1">
              {{ $t('bets.operator') }}
            </h2>
            <div class="flex flex-col md:flex-row gap-1.5">
              <div v-if="!userPlatform" class="w-full">
                <Multiselect
                  mode="single"
                  v-model="selectedPlatform"
                  :options="allPlatforms"
                  :close-on-select="true"
                  :searchable="true"
                  label="name"
                  value-prop="id"
                  :placeholder="$t('bets.selectPlatform')"
                  ref="multiselectPlat"
                />
              </div>
              <div class="w-full">
                <Multiselect
                  mode="single"
                  v-model="selectedOperator"
                  :options="operators"
                  :close-on-select="true"
                  :searchable="true"
                  label="name"
                  value-prop="id"
                  :placeholder="$t('bets.selectOperator')"
                  :loading="loadingOperators"
                  ref="multiselectOp"
                />
              </div>
            </div>
          </div>
          <div class="w-full h-max px-4 pb-5 pt-3 bg-zinc-800 rounded-sm">
            <h2 class="fugaz uppercase text-lg dark:text-white mb-1">
              {{ $t('bets.game') }}
            </h2>
            <div class="w-full">
              <Multiselect
                mode="single"
                v-model="selectedGame"
                :options="platformGames"
                :close-on-select="true"
                :searchable="true"
                label="name"
                value-prop="id"
                :placeholder="$t('bets.selectGame')"
                :classes="{}"
                :loading="loadingPlatformGames"
                ref="multiselectGames"
              />
            </div>
          </div>
        </div>
        <div v-if="!loadingBets && selectedGame !== null" class="flex flex-col mx-3 md:mx-7 mb-4 xl:mb-0 bg-zinc-800 rounded-sm">
          <div v-if="!loadingBets && bets.length > 0 && canChangeBets" class="flex items-center">
            <button @click="openAddingBets" class="py-3.5 w-full rounded-sm flex items-center justify-center text-white rounded-br-sm"
              :class="addingBet ? 'bg-zinc-800' : 'bg-zinc-900 xl:hover:bg-opacity-80'"
            >
              <span class="fugaz uppercase">{{ $t('bets.addBets') }}</span>
            </button>
            <button @click="openRemovingBets" class="py-3.5 w-full rounded-sm flex items-center justify-center text-white rounded-bl-sm"
              :class="removingBets ? 'bg-zinc-800' : 'bg-zinc-900 xl:hover:bg-opacity-80'"
            >
              <span class="fugaz uppercase">{{ $t('bets.removeBets') }}</span>
            </button>
          </div>
          <div class="flex flex-col px-2 py-2 bg-zinc-800 rounded-sm">
            <div v-if="!loadingBets && bets.length > 0" class="flex flex-col">
              <div v-if="canChangeBets" class="">
                <div v-show="addingBet" class="">
                  <form @submit.prevent="addBet" class="flex flex-col gap-3 items-center bg-zinc-900 p-3 w-full rounded-sm">
                    <div clasS="flex flex-col xl:flex-row items-center gap-2.5">
                      <div class="text-white fugaz uppercase">
                        {{ $t('bets.factor') }} {{ factor / 100 }}
                      </div>
                      <div class="flex flex-wrap items-center gap-2 px-4">
                        <input type="number" step="0.1" ref="newBetInput" v-model="newBet" 
                          class="bg-zinc-900 text-white rounded-sm outline-none py-1 px-2.5 border w-52 md:w-96"
                          :class="validNewBet ? 'border-green-500' : 'border-red-500'"  
                        >
                        <button type="submit" 
                          :disabled="!validNewBet"
                          class="flex items-center gap-2 fugaz uppercase text-lg text-white rounded-sm bg-green-600 px-3 py-0.5 xl:hover:bg-green-500 disabled:opacity-50 disabled:pointer-events-none">
                          <span class="-mb-0.5">{{ $t('bets.add') }}</span> 
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div v-show="removingBets" class="">
                  <div class="flex gap-2.5 items-center justify-center bg-zinc-900 py-[17px] w-full text-white fugaz uppercase rounded-sm">
                    <font-awesome-icon :icon="['fas', 'arrow-down']" class="pb-0.5"/>
                    <span>{{ $t('bets.clickOnBets') }}</span>
                    <font-awesome-icon :icon="['fas', 'arrow-down']" class="pb-0.5"/>
                  </div>
                </div>
              </div>
              <div v-else class="w-full text-white">
                <div class="py-3.5 bg-zinc-900 w-full flex items-center justify-center">
                  <h5>{{ $t('bets.notAllowed') }}</h5>
                </div>
              </div>
            </div>
            <div v-if="!loadingBets" class="w-full h-max flex gap-2 mt-2">
              <div v-if="bets.length > 0" class="w-full h-max bg-zinc-900 px-3 pt-3 pb-4 flex flex-col items-center gap-2 rounded-sm relative">
                <button @click="resetBets" 
                  v-if="(JSON.stringify(bets) !== JSON.stringify(originalBets) || betsToRemove.length > 0) && !loadingBets" 
                  class=" text-white xl:hover:text-orange-600 rounded-sm px-2.5 py-1 text-base fugaz uppercase transition-all duration-150 absolute top-0 right-0"
                >
                  {{ $t('bets.reset') }}
                </button>
                <div class="fugaz uppercase text-2xl text-white flex items-center gap-2">
                  <span class="-mb-0.5">{{ $t('bets.title') }}</span> 
                  <img src="../../assets/coin.png" alt="casino chips icon" class="h-6 w-auto invert">
                </div>
                <div class="flex flex-wrap gap-1.5 items-center justify-center w-[90%]">
                  <component v-for="bet in bets" class="bg-zinc-800 cursor-default px-3 py-1 rounded-sm group border"
                    :is="removingBets ? 'button' : 'div'"
                    @click="removeBet(bet)"
                    :class="[
                      (!originalBets.includes(bet) ? 'border-green-500' : betsToRemove.includes(bet) ? 'border-red-500' : 'border-transparent'), 
                      ((canChangeBets && removingBets) && 'cursor-pointer')]"
                  >
                    <span class="text-xl text-zinc-200 fugaz uppercase font-thin transition-colors duration-150" 
                      :class="((canChangeBets && removingBets) && !betsToRemove.includes(bet)) && 'group-hover:text-red-500'">
                      {{ getCurrencyValue((bet / 100).toFixed(2)) }}
                    </span>
                  </component>
                </div>
              </div>
              <div v-if="nothingFound" class="w-full flex justify-center py-2">
                <h4 class="text-white">{{ $t('bets.nothingFound') }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedGame !== null && loadingBets" class="w-full h-full flex items-center justify-center gap-4 pb-6 pt-16">
          <img src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-22 w-auto animate-pulse">
          <!-- <img src="../../assets/loading2.png" alt="loading icon"
            class="text-zinc-400 h-[50px] animate-spin mb-1.5"
          /> -->
        </div>
        <div v-if="(JSON.stringify(bets) !== JSON.stringify(originalBets) || betsToRemove.length > 0) && !savingChanges && !loadingBets" 
          class="w-full h-max flex justify-center xl:mt-2 mb-4 md:mb-0">
          <!-- <button class="fugaz uppercase px-2 py-1 text-white xl:hover:text-orange-600">Save changes</button> -->
          <div
            class="flex flex-col items-center">
            <div
              class="flex gap-2">
              <button @click="savingChanges = true" 
                class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
              >
                {{ $t('bets.saveChanges') }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="savingChanges && (betsToAdd.length > 0 || betsToRemove.length > 0)" class="w-full h-max flex justify-center gap-2">
          <div 
            class="w-max px-4 pt-2 pb-3.5 flex flex-col items-center justify-center gap-3 rounded-sm shadow-md">
            <div class="flex items-center justify-center gap-1.5">
              <div v-if="betsToAdd.length > 0" class="flex flex-col items-center gap-2 bg-zinc-900 p-3">
                <h4 class="text-white fugaz uppercase text-base">
                  {{ $t('bets.sure') }} {{betsToAdd.length > 1 ? $t('bets.theseBets') : $t('bets.thisBets') }}?
                </h4>
                <div class="flex flex-wrap gap-1.5 items-center justify-center w-full">
                  <div v-for="bet in betsToAdd" class="bg-zinc-900 cursor-default px-3 py-1 rounded-sm border border-green-500"
                  >
                    <span class="text-xl text-zinc-200 fugaz uppercase font-thin">
                      {{ getCurrencyValue((bet / 100).toFixed(2)) }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="betsToRemove.length > 0" class="flex flex-col items-center gap-2 bg-zinc-900 p-3">
                <h4 class="text-white fugaz uppercase text-base">
                  {{ betsToAdd.length > 0 ? $t('bets.and') : $t('bets.sure') }} {{ $t('bets.remove') }} 
                  {{ betsToRemove.length > 1 ? $t('bets.theseBets') : $t('bets.thisBets') }}?
                </h4>
                <div class="flex flex-wrap gap-1.5 items-center justify-center w-full">
                  <div v-for="bet in betsToRemove" class="bg-zinc-900 cursor-default px-3 py-1 rounded-sm border border-red-500"
                  >
                    <span class="text-xl text-zinc-200 fugaz uppercase font-thin">
                      {{ getCurrencyValue((bet / 100).toFixed(2)) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center gap-2">
              <button @click="submitBets" class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50">
                {{ $t('bets.confirm') }}
              </button>
              <button @click="cancelChanges" class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50">
                {{ $t('bets.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-full h-[90vh] flex justify-center items-center gap-3 bg-zinc-950 text-zinc-400"
      >
        <img v-if="!serverError" src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
        <!-- <img v-if="!serverError" src="../../assets/loading2.png" alt="loading icon"
          class="text-zinc-400 h-12 animate-spin mb-2"
        /> -->
        <div v-if="serverError">
          {{ $t('serverError') }}
          <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
          {{ $t('tryAgain') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect'
  import request from "@/services/axios";

  export default {
    data() {
      return {
        ready: false,
        serverError: false,
        userPlatform: null,
        allPlatforms: [],
        selectedPlatform: null,
        selectedGame: null,
        selectedOperator: null,
        operators: [],
        loadingOperators: false,
        loadingPlatformGames: false,
        loadingBets: false,
        platformGames: [],
        originalBets: [],
        bets: [],
        betsToAdd: [],
        factor: 0,
        canChangeBets: false,
        addingBet: true,
        newBet: 0.00,
        removingBets: false,
        betsToRemove: [],
        savingChanges: false,
        nothingFound: false,
        mountTimer: null,
      }
    },
    components: {
      Multiselect,
    },
    async mounted() {
      this.mountTimer = setTimeout(async () => {
        await this.getAllPlatforms()
        this.ready = true
      }, 500);
    },
    beforeUnmount() {
      clearTimeout(this.mountTimer);
    },
    watch: {
      selectedPlatform() {
        this.selectedOperator = null
        this.selectedGame = null
        this.platformGames = []
        this.resetChanges()
        this.getPlatformOperators()
        this.$refs.multiselectPlat.blur()
      },
      selectedOperator() {
        this.platformGames = []
        this.selectedGame = null
        this.resetChanges()
        if (this.selectedOperator) this.getPlatformGames()
        this.$refs.multiselectOp.blur()
      },
      async selectedGame() {
        this.loadingBets = true
        this.resetChanges()
        if(this.selectedGame !== null) {
          await this.getBets()
          if(this.addingBet && this.canChangeBets && this.bets.length > 0) {
            this.$nextTick(() => {
              this.$refs.newBetInput.select()
            })
          }
        }
        this.$refs.multiselectGames.blur()
      },
      addingBet() {
        if(this.addingBet) {
          this.$nextTick(() => {
            this.$refs.newBetInput.select()
          })
        }
      },
    },
    computed: {
      user() {
        return this.$store.getters["user/user"]
      },
      validNewBet() {
        if (this.newBet == 0 || this.newBet < 0) return false
        let valid = false
        const bet = this.newBet * 100
        const factor = this.factor
        if (bet % factor === 0) valid = true
        return valid
      }
    },
    methods: {
      resetBets() {
        this.bets = [...this.originalBets]
        this.betsToAdd = []
        this.betsToRemove = []
        this.savingChanges = false
      },
      openAddingBets() {
        this.addingBet = true
        this.removingBets = false
      },
      openRemovingBets() {
        this.removingBets = true
        this.addingBet = false
      },
      resetChanges() {
        this.bets = []
        this.originalBets = []
        this.newBet = 0.00
        this.betsToAdd = []
        this.betsToRemove = []
      },
      async submitBets() {
        this.savingChanges = false
        this.loadingBets = true
        let tempBets = [...this.bets]
        for (const bet of this.bets) {
          if (this.betsToRemove.includes(bet)) {
            const index = tempBets.indexOf(bet)
            tempBets.splice(index, 1)
          }
        }
        const operator = this.operators.find(op => op.id === this.selectedOperator)
        const game = this.platformGames.find(game => game.id === this.selectedGame)
        await request.post(`/platforms/${this.selectedPlatform}/operators/${operator.code}/game/${game.slug}/bets`, {bets: tempBets})
        this.bets = [...tempBets]
        this.originalBets = [...tempBets]
        this.betsToAdd = []
        this.betsToRemove = []
        this.loadingBets = false
        this.$toast.success('Bets updated successfully')
      },
      cancelChanges() {
        this.savingChanges = false
      },
      errorMessage(message) {
        this.$toast.error(message, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      closeAddingBet() {
        this.addingBet = false
      },
      addBet() {
        if(this.validNewBet) {
          const newBet = this.newBet * 100
          if(!this.bets.includes(newBet)) {
            this.bets.push(newBet)
            this.betsToAdd.push(newBet)
          } else this.errorMessage('This bet already exists')
        } else this.errorMessage('Invalid bet')
        this.bets.sort((a, b) => a - b)
        this.betsToAdd.sort((a, b) => a - b)
      },
      removeBet(bet) {
        if(this.canChangeBets && this.removingBets) {
          if(this.betsToAdd.includes(bet)) {
            const index = this.betsToAdd.indexOf(bet)
            this.betsToAdd.splice(index, 1)
            const index2 = this.bets.indexOf(bet)
            this.bets.splice(index2, 1)
            this.bets.sort((a, b) => a - b)
            this.betsToAdd.sort((a, b) => a - b)
            return
          }
          if(!this.betsToRemove.includes(bet)) {
            this.betsToRemove.push(bet)
          } else {
            const index = this.betsToRemove.indexOf(bet)
            this.betsToRemove.splice(index, 1)
          }
          this.bets.sort((a, b) => a - b)
          this.betsToRemove.sort((a, b) => a - b)
        }
      },
      async getAllPlatforms() {
        try {
          if (Object.keys(this.user.attributes).length > 0) {
            const platform = (await request.get(`/platforms/byName/${this.user.attributes.platform[0]}`)).data
            this.userPlatform = platform
            this.getPlatformOperators()
          } else {
            const platforms = (await request.get('/platforms?active=1')).data.rows
            this.allPlatforms = platforms
            this.allPlatforms.sort((a, b) => a.name.localeCompare(b.name))
          }
        } catch (e) {
          console.log(e);
          this.serverError = true;
        }
      },
      async getPlatformOperators() {
        this.operators = []
        this.loadingOperators = true
        try {
          if(this.selectedPlatform) {
            const data = (await request.get(`/platforms/${this.selectedPlatform}/operators`)).data
            this.operators = data
          }
          if(this.userPlatform) {
            const data = (await request.get(`/platforms/${this.userPlatform.id}/operators`)).data
            this.operators = data
          }
          this.operators.sort((a, b) => a.name.localeCompare(b.name))
        } catch (e) {
            console.log(e);
            this.serverError = true;
          }
        this.loadingOperators = false
      },
      async getPlatformGames() {
        this.platformGames = []
        this.loadingPlatformGames = true
        try {
          if(this.selectedPlatform) {
            const data = (await request.get(`/platforms/${this.selectedPlatform}/games`)).data
            data.sort((a, b) => a.name.localeCompare(b.name))
            this.platformGames = data.map(game => {
              return {
                ...game,
                name: game.sub_name ? `${game.name} - ${game.sub_name}` : game.name
              }
            })
          }
          if(this.userPlatform) {
            const data = (await request.get(`/platforms/${this.userPlatform.id}/games`)).data
            data.sort((a, b) => a.name.localeCompare(b.name))
            this.platformGames = data.map(game => {
              return {
                ...game,
                name: game.sub_name ? `${game.name} - ${game.sub_name}` : game.name
              }
            })
          }
        } catch (e) {
          console.log(e);
          this.serverError = true;
        }
        this.loadingPlatformGames = false
      },
      async getBets() {
        this.loadingBets = true
        try {
          const operator = this.operators.find(op => op.id === this.selectedOperator)
          const game = this.platformGames.find(game => game.id === this.selectedGame)
          const platformId = this.userPlatform ? this.userPlatform.id : this.selectedPlatform
          const data = (await request.get(`/platforms/${platformId}/operators/${operator.code}/game/${game.slug}/bets`)).data
          this.bets = [...data.bets]
          this.originalBets = [...data.bets]
          this.factor = data.minFactor
          this.canChangeBets = data.canChangeBets
          this.nothingFound = false
          if (data.bets.length === 0) this.nothingFound = true
        } catch (e) {
          console.log(e);
          this.serverError = true;
          this.bets = []
          this.originalBets = []
          this.factor = 0
          this.canChangeBets = null
          this.nothingFound = true
          this.errorMessage('Something went wrong, please try again')
        }
        this.loadingBets = false
      }
    }
  }
</script>

<style scoped>

</style>