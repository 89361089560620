<template>
  <div class="bg-zinc-950 relative shadow-md w-full xl:h-max flex flex-col gap-1.5 z-[20] pb-[55px] xl:pb-0">
    <h1 class="text-xl xl:text-2xl text-center fugaz uppercase text-white">
      {{ $t('manageGames.platformsVisibility') }}
    </h1>
    <div class="flex flex-col bg-zinc-800 mx-3 md:mx-8 rounded-md">
      <div class="w-full flex items-center justify-center">
        <button @click="type = 'platform'" class="w-full py-1 md:py-2 px-2 rounded-tl-md rounded-br-lg text-white transition-all duration-100"
          :class="type === 'platform' ? 'bg-zinc-800' : 'bg-zinc-900  xl:hover:text-orange-600'"
        >
          <div 
            class="text-base md:text-lg text-center px-3 pt-1.5 pb-1 fugaz uppercase rounded-tr-md flex items-center justify-center gap-1.5"
          >
            <span class="">{{ $t('manageGames.platform') }} <font-awesome-icon :icon="['fas', 'arrow-right']" class="h-4 px-0.5" /> {{ $t('manageGames.games') }}</span>
            <!-- <font-awesome-icon :icon="['fas', 'gear']" class="h-5 pb-1" /> -->
          </div>
        </button>
        <button @click="type = 'game'" class="w-full py-1 md:py-2 px-2 rounded-tr-md rounded-bl-lg text-white transition-all duration-100"
          :class="type === 'game' ? 'bg-zinc-800' : 'bg-zinc-900  xl:hover:text-orange-600'"
        >
          <div 
            class="text-base md:text-lg text-center px-3 pt-1.5 pb-1 fugaz uppercase rounded-md flex items-center justify-center gap-1.5"
          >
            <span class="">{{ $t('manageGames.game') }} <font-awesome-icon :icon="['fas', 'arrow-right']" class="h-4 px-0.5" /> {{ $t('manageGames.platforms') }}</span>
            <!-- <font-awesome-icon :icon="['fas', 'gear']" class="h-5 pb-1" /> -->
          </div>
        </button>
      </div>
      <div v-if="ready" class="w-full h-full flex">
        <ManagePlatform v-if="type === 'platform'" :allGames="gamesFromDB" :allPlatforms="allPlatforms" :sortByName="sortByName"/>
        <ManageGame v-if="type === 'game'" :allGames="gamesFromDB" :allPlatforms="allPlatforms" :sortByName="sortByName" />
      </div>
      <div
        v-else
        class="w-full h-full min-h-[70vh] flex justify-center items-center gap-3 pt-18 text-white bg-zinc-900 mt-2 rounded-b-md"
      >
        <img v-if="!serverError" src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-22 w-auto animate-pulse">
        <!-- <img
          v-if="!serverError"
          src="../../assets/loading2.png"
          alt="loading icon"
          class="h-12 animate-spin mb-2"
        /> -->
        <div v-else>
          {{ $t('serverError') }}
          <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
          {{ $t('tryAgain') }}
        </div>
      </div>
    </div>
    <ManageCertificates v-if="ready" :allGames="gamesFromDB" :allPlatforms="allPlatforms" :sortByName="sortByName"/>
  </div>
</template>
<script>
  import request from '@/services/axios'
  import Multiselect from '@vueform/multiselect'
  import ManagePlatform from '../../components/games/ManagePlaform.vue'
  import ManageGame from '../../components/games/ManageGame.vue'
  import ManageCertificates from '../../components/games/ManageCertificates.vue'

  export default {
    data() {
      return {
        type: 'platform',
        gamesFromDB: [],
        allPlatforms: [],
        selectedGameId: null,
        serverError: false,
        ready: false,
      }
    },
    components: {
      Multiselect,
      ManagePlatform,
      ManageGame,
      ManageCertificates,
    },
    props: {
      games: Array,
    },
    async created() {
      await this.getGamesFromDB()
      await this.getAllPlatforms()
      this.ready = true
      this.emitter.on('platformGamesUpdated', this.getGamesFromDB);
      this.emitter.on('gamePlatformsUpdated', this.getAllPlatforms)
    },
    methods: {
      sortByName(a, b) {
        return a.name.localeCompare(b.name)
      },
      async getGamesFromDB() {
        const games = this.games
        games.sort(this.sortByName)
        this.gamesFromDB = games.map(game => {
          return {
            ...game,
            name: game.sub_name ? `${game.name} - ${game.sub_name}` : game.name
          }
        })
      },
      async getAllPlatforms() {
        try {
          const platforms = (await request.get('/platforms?with=games&active=1')).data.rows
          this.allPlatforms = platforms
          this.allPlatforms.sort(this.sortByName)
        } catch (e) {
          console.log(e);
          this.serverError = true;
        }
      },
    },
  }
</script>
<style scoped>

</style>